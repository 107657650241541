import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HeartCardiogram1 } from "../icons/HeartCardiogram1/HeartCardiogram1";
import "./style.css";
import Screenshot from "../static/img/screenshot-2024-10-13-at-4-12-12-pm-removebg-preview-1.png";
import Logo from "../static/img/georgia-tech-logo-1.png";
import { postJSON } from "../utils/utils";

export const LoginPage = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleLogin = async () => {
    let isError = false;

    if (email === "") {
      setErrorMessage("Please enter your email");
      isError = true;
    } else if (password === "") {
      setErrorMessage("Please enter your password");
      isError = true;
    }

    setError(isError);

    if (isError) {
      return;
    }

    const data = {
        email: email,
        password: password
    };

    const responseData = await postJSON("/api/login", data);
  
    if ("error" in responseData) {
        setErrorMessage(responseData["error"]);
        isError = true;
    } else {
        navigate("/");
    }

    setError(isError);
  };

  return (
    <div className="login-page">
      <div className="div">
        <div className="overlap">
          <div className="overlap-group">
            <div className="rectangle" />
            <img className="screenshot" alt="Screenshot" src={Screenshot} />
          </div>
          <img className="georgia-tech-logo" alt="Georgia tech logo" src={Logo} />
        </div>

        <div className="text-wrapper-3">Welcome Back!</div>

        <div className="text-wrapper">Email:</div>
        <input type="text" className="rectangle-2" value={email} onChange={handleEmailChange} onKeyDown={handleKeyDown} />

        <div className="text-wrapper-7">Password:</div>
        <input type="password" className="rectangle-3" value={password} onChange={handlePasswordChange} onKeyDown={handleKeyDown} />

        <div className="group-buttons">
          {error && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <div className="frame">
            <div className="frame-2">
              <input type="checkbox" className="rectangle-4" />
              <div className="text-wrapper-4">Remember me</div>
            </div>
            <Link to="/signup" className="text-wrapper-5">
              Forgot password?
            </Link>
          </div>

          <div className="group">
            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
          </div>

          <p className="dont-have-an-account">
            <span className="span">Dont have an account?</span>
            <Link to="/signup" className="text-wrapper-2">
              Register
            </Link>
          </p>
        </div>

        <div className="heart-rate-icon">
          <HeartCardiogram1 className="heart-cardiogram" />
        </div>
      </div>
    </div>
  );
};
