import React, { useState }  from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import BG from "../static/img/signup-bg.png";
import Logo from "../static/img/georgia-tech-logo-1.png";
import Cardio from "../static/img/signup-cardio.svg";
import Fhir from "../static/img/logo-fhir-island.png";
import { postJSON } from "../utils/utils";

export const SignUp = (): JSX.Element => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: ""
  });

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        username: username,
        password: password
    };

    const response = await postJSON("/api/create-account", data);
    const errorsData: any = {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: ""
    }

    if ("error" in response) {
      const items: any = response.error;
      for (const [key, value] of Object.entries(items)) {
        errorsData[key] = value;
      }
    } else {
      navigate("/");
    }

    setErrors(errorsData);
  }

  return (
    <div className="new-user-sign-up">
      <img className="bg-icon" alt="" src={BG} />
      <b className="create-an-account">Create an account</b>
      <div className="first-name">First Name:</div>
      <div className="last-name">Last Name:</div>
      <div className="email">Email:</div>
      <div className="username">Username:</div>
      <div className="password">Password:</div>

      <input
        type="text"
        className={`rectangle-div text-input ${errors.firstName ? 'error-border' : ''}`}
        onChange={handleFirstNameChange}
        onKeyDown={handleKeyDown} />
      
      <input
        type="text"
        className={`new-user-sign-up-child1 text-input ${errors.lastName ? 'error-border' : ''}`}
        onChange={handleLastNameChange}
        onKeyDown={handleKeyDown} />

      <input
        type="text"
        className={`new-user-sign-up-child text-input ${errors.email ? 'error-border' : ''}`}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown} />

      <input
        type="text"
        className={`new-user-sign-up-inner text-input ${errors.username ? 'error-border' : ''}`}
        onChange={handleUsernameChange}
        onKeyDown={handleKeyDown} />

      <input
        type={showPassword ? 'text' : 'password'}
        className={`new-user-sign-up-item text-input ${errors.password ? 'error-border' : ''}`}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown} />

      {errors.firstName &&
        <div className="first-name-error form-error">{errors.firstName}</div>
      }
      {errors.lastName &&
        <div className="last-name-error form-error">{errors.lastName}</div>
      }
      {errors.email &&
        <div className="email-error form-error">{errors.email}</div>
      }
      {errors.username &&
        <div className="username-error form-error">{errors.username}</div>
      }
      {errors.password &&
        <div className="password-error form-error">{errors.password}</div>
      }

      <div className="rectangle-parent">
          <input type="checkbox" className="show-password-checkbox" onChange={handleShowPasswordChange} />
          <div className="show-password">Show password</div>
      </div>
      <button className="sign-up" onClick={handleSignUp}>Sign Up</button>
      <img className="georgia-tech-logo-1" alt="" src={Logo} />
      <div className="have-an-account-login">
        <div className="show-password">
          <span className="already-have-an">Already have an account?</span>
          <Link to="/login" className="log-in">
            Log in
          </Link>
        </div>
      </div>
      <div className="heart-rate-icon-solid">
          <div className="heart-rate-icon-solid-child" />
          <img className="heart-cardiogram-icon" alt="" src={Cardio} />
      </div>
      <div className="your-all-in-one-risk-container">
          <p className="your-all-in-one-risk">Your All-in-One Risk Calculator Hub for</p>
          <p className="your-all-in-one-risk">Smarter, Faster Medical Decisions</p>
      </div>
      <img className="logo-fhir-island-icon" alt="" src={Fhir} />
    </div>
  );
}
